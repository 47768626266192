import '../styles/global.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appWithTranslation } from '@dx-ui/framework-i18n';
import { QueryProvider } from '@dx-ui/framework-react-query';
import { APP_NAME, queryClientProps } from '../utils/env-vars';
import type { AppProps as NextAppProps } from 'next/app';
import { useManualPageLogger } from '@dx-ui/framework-logger';
import { useRouter } from 'next/router';
import type { CommonProps } from '../utils/serverSideProps';

type AppProps = NextAppProps<CommonProps>;

function App({ Component, pageProps, router }: AppProps) {
  useManualPageLogger(router.pathname, APP_NAME);
  const routerLocale = useRouter().locale || router.locale || 'en';

  return (
    <QueryProvider
      {...queryClientProps}
      appName={APP_NAME}
      dehydratedQueryState={pageProps.dehydratedQueryState}
      oneLinkConfig={pageProps.oneLinkConfig}
      routerLocale={routerLocale}
    >
      <Component {...pageProps} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryProvider>
  );
}

export default appWithTranslation<AppProps>(App);
