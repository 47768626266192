import { env } from '@dx-ui/framework-env';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import type { FlatNamespace } from 'i18next';

export const queryClientProps = getQueryProviderProps();
export const ASSETS_URI = env('ASSETS_URI');
export const AUTOCOMPLETE_URL = env('AUTOCOMPLETE_URL');
export const CONDUCTRICS_URI = env('CONDUCTRICS_URI');
export const OHW_BASE_URL_TRIMMED = env('OHW_BASE_URL').replace(/\/$/, '') || '';
export const DX_AUTH_UI = env('DX_AUTH_UI');
export const APP_NAME = env('APP_NAME', 'dx-offers-ui');

export const GLOBAL_RESOURCE_BUNDLES = [
  'common',
  'offers',
  'osc-breadcrumbs',
  'osc-footer',
  'osc-header',
  'osc-link',
  'osc-speedbump',
] as const satisfies FlatNamespace[];
