import { useAuth } from '@dx-ui/framework-auth-provider';
import type { TDrawerItem } from '@dx-ui/osc-header';
import { Header } from '@dx-ui/osc-header';
import { useTranslation } from 'next-i18next';
import { useNavigationQuery } from '../generated/react-query';
import { DX_AUTH_UI, OHW_BASE_URL_TRIMMED } from '../utils/env-vars';

const useActiveNavLinks = ({ brandCode, language }: { brandCode: string; language?: string }) => {
  const { data } = useNavigationQuery({ brandCode, language: language || 'en' });
  if (data?.brand?.header?.sections.length === 1) {
    return data?.brand?.header?.sections?.[0]?.links.map((link) => {
      return {
        label: link.label,
        link: { ...link },
        subMenu: link.secondaryLinks.map((secondaryLink) => {
          return { label: secondaryLink.label, link: { ...secondaryLink } };
        }),
      };
    }) as TDrawerItem[];
  } else if (data?.brand?.header?.sections.length && data?.brand?.header?.sections.length > 1) {
    return data?.brand?.header?.sections?.flatMap((section) => {
      return section.links.map((link) => {
        return {
          label: link.label,
          link: { ...link },
          subMenu: link.secondaryLinks.map((secondaryLink) => {
            return { label: secondaryLink.label, link: { ...secondaryLink } };
          }),
        };
      });
    }) as TDrawerItem[];
  }
};

export function OffersHeader({
  brandCode = 'WW',
  brandName = 'Hilton',
  brandUrl = '',
  language = 'en',
}) {
  const { login, guestInfo, logout } = useAuth();
  const { t } = useTranslation('common');

  const navItems = useActiveNavLinks({ brandCode, language });

  return (
    <Header
      megaNavLinks={navItems}
      user={
        guestInfo
          ? {
              name: guestInfo.personalinfo?.name?.firstName,
              honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
              honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
              honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
            }
          : undefined
      }
      onSignOut={async () => {
        await logout();
      }}
      onSignInAttempt={async ({ data }) => {
        if (data) {
          await login({ data });
        }
      }}
      brand={{
        url: brandUrl || 'https://www.hilton.com',
        code: brandCode,
        name: brandCode === 'WW' ? t('forTheStay') : brandName,
      }}
      userLinks={{
        signInLink: {
          url: DX_AUTH_UI?.replace('__LANG__', language),
        },
        accountLink: {
          url: `${OHW_BASE_URL_TRIMMED}/${language}/hilton-honors/guest/my-account/`,
        },
        signUpLink: {
          url: `${OHW_BASE_URL_TRIMMED}/${language}/hilton-honors/join/`,
        },
      }}
    />
  );
}
