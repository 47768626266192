import { OfferListing, serverSideBrandOfferListingQuery } from '@dx-ui/content-offers-components';
import Head from 'next/head';
import { getServerSideClients, getServerSideSafeLanguage } from '@dx-ui/framework-react-query';
import { OffersHeader } from '../../components/header';
import { OffersFooter } from '../../components/footer';
import { useRouter } from 'next/dist/client/router';
import { ViewDetailsLink } from '../../components/view-details';
import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { ASSETS_URI, OHW_BASE_URL_TRIMMED, queryClientProps } from '../../utils/env-vars';
import type { PageMetricsListing } from '../../components/analytics';
import { Analytics } from '../../components/analytics';
import { serverSideBrandQuery, serverSideNavigationQuery } from '../../generated/react-query';
import {
  sendCTAClickedOfferId,
  useConductricsSortOrder,
  useInitializeConductrics,
} from '../../utils/conductrics-sort-order';
import { sendReward } from '@dx-ui/framework-conductrics';

import { serverSideProps } from '../../utils/serverSideProps';
import { logError } from '@dx-ui/framework-logger';

// NHCBP-5467 - conductrics test code for non-ca and usa users
function sendNonCanUsaReward(offerId: number) {
  switch (offerId) {
    case 2000000046:
      sendReward('g-2xpoints');
      break;
    case 2000000047:
      sendReward('g-honorsdiscountadvancepurchase');
      break;
    case 2000000544:
      sendReward('g-pointspluspromotion');
      break;
    default:
      break;
  }
}

export function Index({ seo, metrics }: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { locale } = useRouter();
  useInitializeConductrics();
  const sortOrder = useConductricsSortOrder();
  return (
    <>
      <Analytics metrics={metrics} />
      <Head>
        <title>{seo?.pageTitle}</title>
        <meta name="keywords" content={seo?.metaKeywords || ''} />
        <meta name="description" content={seo?.metaDescription || ''} />
        {seo?.hideSearchEngine ? <meta name="robots" content="noindex,follow" /> : null}

        <link rel="canonical" href={`${OHW_BASE_URL_TRIMMED}/${locale}/offers/`} />
      </Head>
      <OffersHeader />
      <div className="container mx-auto my-4">
        <OfferListing
          sortOffers={sortOrder}
          language="en"
          brandCode="WW"
          assetUrl={ASSETS_URI}
          renderAdditionalComponents={(data) => {
            return (
              <Head>
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@Hilton" />
                <meta property="og:url" content={`${OHW_BASE_URL_TRIMMED}/${locale}/offers/`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={seo?.pageTitle || ''} />
                <meta property="og:description" content={seo?.metaDescription || ''} />
                <meta property="og:site_name" content="Hilton" />
                <meta property="og:image" content={data.firstImageForOg} />
              </Head>
            );
          }}
          offerCTAClick={(offer) => {
            sendCTAClickedOfferId(offer.id);
          }}
          renderInternalOfferLink={(offer) => (
            <ViewDetailsLink
              onClick={() => {
                sendCTAClickedOfferId(offer.id);

                sendNonCanUsaReward(offer.id);
              }}
              offer={offer}
              hrefPrefix={`/${locale}/offers/`}
            />
          )}
        />
      </div>
      <OffersFooter brandCode="WW" language={locale || 'en'} />
    </>
  );
}

export const getServerSideProps = async function getServerSideProps(context) {
  const { res, req } = context;
  const locale = getServerSideSafeLanguage(context);
  const toggleHeader = req.headers['dx-toggles'] as string;
  const { queryClient } = getServerSideClients({
    ...queryClientProps,
    response: res,
    appName: 'dx-offers-ui',
    customHeaders: {
      'dx-toggles': toggleHeader,
    },
  });

  const [brandOfferListingResult] = await Promise.all([
    serverSideBrandOfferListingQuery(queryClient, {
      language: locale,
      brandCode: 'WW',
    }).catch(async () => {
      try {
        return await serverSideBrandOfferListingQuery(queryClient, {
          language: 'en',
          brandCode: 'WW',
        });
      } catch (error) {
        logError('FETCH_ERROR', error as Error, 'serverSideBrandOfferListingQuery');
        return null;
      }
    }),
  ]);

  const brandCode = 'HI';
  const seo = brandOfferListingResult?.brand?.page?.seo || {};
  const metrics: PageMetricsListing = {
    brandCode,
    brandName: brandOfferListingResult?.brand?.formalName || '',
    language: locale,
    primaryCategory: 'Portfolio',
    trackCallName: 'offerListingPageView',
  };

  return serverSideProps({
    context,
    namespaces: ['osc-pagination'],
    queryClient,
    pageProps: {
      seo,
      metrics,
    },
    getServerSideQueries({ queryClient, language }) {
      return [
        serverSideBrandQuery(queryClient, { language, brandCode: 'WW' }),
        serverSideNavigationQuery(queryClient, { language, brandCode: 'WW' }),
      ];
    },
  });
} satisfies GetServerSideProps;

export default Index;
